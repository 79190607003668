(function () {
    'use strict';

    angular.module('stpApp').controller('ImportRouteDialogController', ImportRouteDialogController);

    ImportRouteDialogController.$inject = [
        '$uibModalInstance',
        'parsedFileResult',
        'project',
        'RoutePlanningModalFactory',
        'depot',
        'mapProp',
        'Project',
        '$q',
        'RouteDayWeekGen',
        'DayGap'
    ];

    function ImportRouteDialogController(
        $uibModalInstance,
        parsedFileResult,
        project,
        RoutePlanningModalFactory,
        depot,
        mapProp,
        Project,
        $q,
        RouteDayWeekGen,
        DayGap
    ) {
        var vm = this;
        vm.project = project;
        vm.depot = depot;
        vm.close = close;
        vm.isSaving = false;
        vm.keys = parsedFileResult.keys ? parsedFileResult.keys : [];
        vm.data = parsedFileResult.data ? parsedFileResult.data : [];
        vm.keyMapper = {
            external_id: null,
            territory: null,
            week: null,
            day: null,
            sequence: null,
        };
        vm.columnAliases = {
            external_id: ['external_id', 'external', 'id', 'stop_id', 'stop'],
            territory: ['territory_name', 'territory name', 'name'],
            week: ['week'],
            day: ['day'],
            sequence: ['sequence'],
        };
        vm.mode = 'MAPPER';
        vm.mapperForm = {};
        vm.tableFrom = {};
        vm.tableData = [];
        vm.page = 1;
        vm.totalItems = 0;
        vm.itemsPerPage = 10;
        vm.filterValidData = '';
        vm.isShowValid = false;
        vm.isHeaderDuplicate = false;
        vm.valuesConfig = [];
        vm.territories = [];
        vm.mapProp = mapProp;
        vm.outlets = [];
        vm.maxDay = vm.project.props.maxDay;
        vm.maxWeek = null;
        vm.isLoadingOutlet = true;

        vm.createTableData = createTableData;
        vm.onMapperChange = onMapperChange;
        vm.uploadFile = uploadFile;
        vm.stopSummary = {
            depotCount: 0,
            outletCount: 0,
        };

        vm.renderTerritoryData = renderTerritoryData;
        vm.renderStopData = renderStopData;
        vm.getOrdinalSuffix = getOrdinalSuffix;
        vm.deleteRow = deleteRow;
        vm.inputFormatter = inputFormatter;
        vm.onSelectStop = onSelectStop;
        vm.onSelectTerritory = onSelectTerritory;
        vm.closeOtherActiveField = closeOtherActiveField;
        vm.newTableData = newTableData;
        vm.filter = {
            stop: '',
            territory: '',
            visitFreq: '',
            firstWeek: '',
            firstDay: '',
            invalid: false,
        };
        vm.invalidRow = invalidRow;
        vm.validRow = validRow;
        vm.onFirstWeekUpdate = onFirstWeekUpdate;
        vm.onFirstDayUpdate = onFistDayUpdate;
        vm.removeExcessiveVisit = removeExcessiveVisit;
        vm.onSequenceUpdate = onSequenceUpdate;
        vm.closeEsc = closeEsc;
        vm.resolveVisit = resolveVisit;
        vm.resolveAllVisit = resolveAllVisit;
        vm.showResolveErrorShedule = showResolveErrorShedule;
        vm.errorTooltips = errorTooltips;
        vm.outletPage = 0;

        simpleAutoMapper();
        getTerritoriesByDepotId();
        getMaxWeekByDepotId();
        getOutlets();

        function close() {
            $uibModalInstance.dismiss();
        }

        function simpleAutoMapper() {
            Object.keys(vm.keyMapper).forEach(function (mapper) {
                var idx = vm.keys.findIndex(function (key) {
                    var similarCandidate = vm.columnAliases[mapper].filter(function (alias) {
                        return (
                            stringSimilarity.compareTwoStrings(key.toLowerCase(), alias.toLowerCase()) >= 0.7
                        );
                    });
                    return similarCandidate.length > 0;
                });
                if (idx >= 0) {
                    vm.keyMapper[mapper] = vm.keys[idx];
                }
            });
        }

        function createTableData() {
            if (vm.tableData.length) {
                vm.mode = 'DATA';
                return;
            }

            var rawData = vm.data.map(function (each) {
                var externalId = each[vm.keyMapper['external_id']];
                var territory = each[vm.keyMapper['territory']];
                var week = each[vm.keyMapper['week']];
                var day = each[vm.keyMapper['day']];
                var sequence = each[vm.keyMapper['sequence']];

                return {
                    externalId: externalId,
                    territory: territory,
                    week: week,
                    day: day,
                    sequence: sequence,
                };
            });

            var groupedData = rawData.reduce(function (acc, current) {
                // Find the index of an existing group with the same externalId and territory
                var index = acc.findIndex(function (item) {
                    return item.externalId === current.externalId && item.territory === current.territory;
                });

                // If the group exists, add the current visit to the visits array
                if (index !== -1) {
                    acc[index].visits.push({
                        week: current.week,
                        day: current.day,
                        sequence: current.sequence,
                    });
                }

                // If the group does not exist, create a new group
                else {
                    acc.push({
                        externalId: current.externalId,
                        territory: current.territory,
                        visits: [
                            {
                                week: current.week,
                                day: current.day,
                                sequence: current.sequence,
                            },
                        ],
                    });
                }

                return acc;
            }, []);

            vm.tableData = groupedData.map(function (gd, idx, arr) {
                var territory = vm.territories.find(function (t) {
                    return t.id == gd.territory;
                });

                var stop = vm.outlets.find(function (o) {
                    return o.externalId == gd.externalId;
                });

                gd.visits = sortWeekDay(gd.visits);

                var firstWeek = gd.visits.length ? gd.visits[0].week : null;
                var firstDay = gd.visits.length ? gd.visits[0].day : null;
                var visitFreq = stop ? stop.visitFreq : null;
                var dayFreq = visitFreq ? Number(visitFreq.split('')[0]) : null; // recall when change stop
                var weekFreq = visitFreq ? Number(visitFreq.split('')[2]) : null; // recall when change stop

                var data = {
                    id: idx,
                    territory: {
                        data: gd.territory,
                        util: {
                            error: validateTerritory(gd.territory, territory),
                            selected: territory,
                            editMode: false,
                        },
                    },
                    stop: {
                        data: gd.externalId,
                        util: {
                            error: validateStop(gd.externalId, stop, arr),
                            selected: stop,
                            editMode: false,
                        },
                    },
                    visitFreq: {
                        data: visitFreq,
                        util: {
                            error: visitFreq ? null : { required: true },
                        },
                    },
                    firstWeek: {
                        data: firstWeek,
                        util: {
                            error: validateWeek(firstWeek),
                            editMode: false,
                        },
                    },
                    firstDay: {
                        data: firstDay,
                        util: {
                            error: validateDay(firstDay),
                            editMode: false,
                        },
                    },
                    dayFreq: dayFreq, // recall when change stop
                    weekFreq: weekFreq, // recall when change stop
                    visits: gd.visits,
                    rowValid: true,
                };

                data.numberOfVisits = getNumberOfVisits(data); // recall when change stop
                data.weekOpts = getWeekOptions(data); // recall when change stop
                data.dayOpts = getDayOptions(data); // recall when change stop
                data.visits = visitsMapper(data);

                // proses validasi visits
                data.visits = validateVisitFormat(data);
                data.visits = validateVisitPattern(data);
                data.visitsError = validateVisitGroup(data);

                addVisit(data);

                data.rowValid = validateRowData(data); // recall when change stop

                return data;
            });

            vm.totalItems = vm.tableData.length;
            vm.mode = 'DATA';
        }

        function onMapperChange() {
            vm.tableData = [];
            vm.page = 1;
            vm.isHeaderDuplicate = false;
        }

        function uploadFile() {
            if (vm.invalidRow == vm.tableData.length) {
                return;
            }

            var validData = vm.tableData.filter(function (td) {
                return td.rowValid;
            });

            var invalidData = vm.tableData.filter(function (td) {
                return !td.rowValid;
            });

            var cautionImportDialog = RoutePlanningModalFactory.openImportRouteCautionDialog({
                project: vm.project,
                depot: vm.depot,
                totalItems: vm.totalItems,
                invalidData: invalidData,
                validData: validData,
            });
            cautionImportDialog.result.then(
                function () {
                    var res = {
                        reload: true,
                        depotId: vm.depot.id,
                    };
                    $uibModalInstance.close(res);
                },
                function () {}
            );
        }

        function getTerritoriesByDepotId() {
            if (!vm.mapProp.style.depots) {
                return;
            }

            var depotStyle = vm.mapProp.style.depots[vm.depot.id];
            var territories = depotStyle.territories;
            var territoriesKeys = Object.keys(territories);

            if (territoriesKeys && territoriesKeys.length) {
                for (var k in territories) {
                    vm.territories.push({ id: k, name: territories[k].name });
                }
            }
        }

        function getMaxWeekByDepotId() {
            if (!vm.project.props.depotMaxWeeks) {
                return;
            }

            var depotMaxWeeks = vm.project.props.depotMaxWeeks;

            var findDepot = depotMaxWeeks.find(function (dw) {
                return dw.depotId === vm.depot.id;
            });

            vm.maxWeek = findDepot ? findDepot.maxWeek : null;
        }

        function getOutlets() {
            var params = {
                projectId: vm.project.id,
                'depotId.equals': vm.depot.id,
                page: vm.outletPage,
                size: 2000,
                sort: ['name,asc'],
            };

            Project.getOutlets(
                params,
                function (data, headers) {
                    var total = headers('X-Total-Count');
                    vm.outlets = vm.outlets.concat(data);

                    if (vm.outlets.length < total) {
                        getOutlets();
                    } else {
                        vm.isLoadingOutlet = false;
                    }
                },
                function () {
                    vm.isLoadingOutlet = false;
                }
            );
        }

        function sortWeekDay(arr) {
            if (!arr) {
                return;
            }
            arr.sort(function (a, b) {
                // Sort by 'week' first
                if (a.week === null && b.week !== null) return 1;
                if (a.week !== null && b.week === null) return -1;
                if (a.week === null && b.week === null) return 0;
                if (angular.isNumber(a.week) && angular.isNumber(b.week)) {
                    if (a.week !== b.week) return a.week - b.week;
                } else {
                    return String(a.week).localeCompare(String(b.week));
                }

                // If 'week' is the same, sort by 'day'
                if (a.day === null && b.day !== null) return 1;
                if (a.day !== null && b.day === null) return -1;
                if (a.day === null && b.day === null) return 0;
                return a.day - b.day;
            });

            return arr;
        }

        function validateTerritory(data, territory) {
            if (data && territory) {
                return null;
            } else if (data && !territory) {
                return { notRegistered: true };
            } else if (!data && !territory) {
                return { required: true };
            }
        }

        function validateStop(data, stop, arr) {
            if (data && stop) {
                var findDuplicate = arr.filter(function (a) {
                    return a.externalId == data;
                });

                return findDuplicate.length > 1 ? { duplicate: true } : null;
            } else if (data && !stop) {
                return { notRegistered: true };
            } else if (!data && !stop) {
                return { required: true };
            }
        }

        function validateWeek(data) {
            if (!data) {
                return { required: true };
            } else if (isNaN(data)) {
                return { notNumber: true };
            } else if (data > vm.maxWeek || data < 1) {
                return { outOfRange: true };
            } else {
                return null;
            }
        }

        function validateDay(data) {
            if (!data) {
                return { required: true };
            } else if (isNaN(data)) {
                return { notNumber: true };
            } else if (data > vm.maxDay || data < 1) {
                return { outOfRange: true };
            } else {
                return null;
            }
        }

        function validateRowData(data) {
            if (
                data.territory.util.error ||
                data.stop.util.error ||
                data.visitFreq.util.error ||
                data.firstWeek.util.error ||
                data.firstDay.util.error ||
                data.visitsError
            ) {
                return false;
            } else {
                return true;
            }
        }

        function renderTerritoryData(item) {
            if (!item) {
                return '';
            }

            var selected = item.territory.util.selected;
            var res = '';

            if (!selected) {
                res += item.territory.data;
            } else if (selected.id && selected.name) {
                res = selected.id + ' - ' + selected.name;
            } else if (selected.id) {
                res += selected.id;
            } else if (selected.name) {
                res += selected.name;
            }

            return res;
        }

        function renderStopData(item) {
            if (!item) {
                return '';
            }

            var selected = item.stop.util.selected;
            var res = '';

            if (!selected) {
                res += item.stop.data;
            } else if (selected.externalId && selected.name) {
                res = selected.externalId + ' - ' + selected.name;
            } else if (selected.externalId) {
                res += selected.externalId;
            } else if (selected.name) {
                res += selected.name;
            }

            return res;
        }

        function visitsMapper(data) {
            var visits = data.visits.map(function (visit, idx) {
                var dataVisit = {
                    number: getOrdinalSuffix(idx + 1),
                    week: {
                        data: visit.week,
                        error: null,
                    },
                    day: {
                        data: visit.day,
                        error: null,
                    },
                    sequence: {
                        data: visit.sequence,
                        error: null,
                    },
                };

                return dataVisit;
            });

            return visits;
        }

        function validateVisitFormat(data) {
            if (!data.visits.length) {
                // Kembalikan array kosong jika visits kosong
                return [];
            }

            return data.visits.map(function (visit, idx) {
                var before = data.visits.slice(0, idx + 1);
                var findDuplicate = before.filter(function (b) {
                    return visit.week.data === b.week.data && visit.day.data === b.day.data;
                });

                // Salin objek visit untuk menghindari mutasi
                var newVisit = {
                    week: {
                        data: visit.week.data,
                        error: validateWeek(visit.week.data),
                    },
                    day: {
                        data: visit.day.data,
                        error: validateDay(visit.day.data),
                    },
                    sequence: {
                        data: visit.sequence.data,
                        error: !isNaN(visit.sequence.data) ? null : { notNumber: true },
                    },
                };

                // validate excessive
                if (idx + 1 > data.numberOfVisits) {
                    newVisit.week.error = { excessiveRoute: true };
                    newVisit.day.error = { excessiveRoute: true };
                }

                // validate duplicate
                if (findDuplicate.length > 1) {
                    newVisit.week.error = newVisit.week.error
                        ? newVisit.week.error
                        : { duplicateRoute: true };
                    newVisit.day.error = newVisit.day.error ? newVisit.day.error : { duplicateRoute: true };
                }

                return newVisit;
            });
        }

        function validateVisitPattern(data) {
            if (!data.visits.length) {
                return [];
            }

            if (!data.dayFreq || !data.weekFreq || data.firstWeek.util.error || data.firstDay.util.error) {
                return data.visits;
            }

            var someWeekOrDayError = data.visits.some(function (visit) {
                var weekInvalid = visit.week.data == null || isNaN(visit.week.data);
                var dayInvalid = visit.day.data == null || isNaN(visit.week.data);
                return (weekInvalid) || (dayInvalid);
            });

            function getWeekGaps(firstWeek, maxWeek, weekGap) {
                var weekLength = maxWeek / weekGap;
                var weeks = [];

                for (var i = 0; i < weekLength; i++) {
                    weeks.push(firstWeek);

                    if (firstWeek + weekGap > maxWeek) {
                        firstWeek = firstWeek + weekGap - maxWeek;
                    } else {
                        firstWeek += weekGap;
                    }
                }

                return weeks.sort(function (a, b) {
                    return a - b;
                });
            }

            // untuk kasus d = 1;
            var firstWeek = Number(data.firstWeek.data);
            var weekgaps = getWeekGaps(firstWeek, vm.maxWeek, data.weekFreq);

            // untuk kasus d > 1
            var uniqueDays = [];
            var weekCounter = {};

            if (someWeekOrDayError) {
                return data.visits;
            }

            if (data.dayFreq == 1) {
                // validasi pattern
                var visitsPattern = data.visits.map(function (visit, idx) {
                    // Salin objek visit untuk menghindari mutasi
                    var newVisit = {
                        week: {
                            data: Number(visit.week.data),
                            error: visit.week.error || null,
                        },
                        day: {
                            data: Number(visit.day.data),
                            error: visit.day.error || null,
                        },
                        sequence: {
                            data: visit.sequence.data,
                            error: visit.sequence.error || null,
                        },
                    };

                    var isWeekInGap = weekgaps.find(function (wg) {
                        return wg == visit.week.data;
                    });
                    var sameAsFirstDay = visit.day.data == data.visits[0].day.data;

                    // check if week number on week gap list
                    if (!isWeekInGap) {
                        newVisit.week.error = newVisit.week.error
                            ? newVisit.week.error
                            : { patternError: true };
                    }

                    // check if day is same as first day
                    if (!sameAsFirstDay) {
                        newVisit.day.error = newVisit.day.error
                            ? newVisit.day.error
                            : { patternError: true };
                    }

                    // initial counter by week number
                    if (!weekCounter[visit.week.data]) {
                        weekCounter[visit.week.data] = 1;
                    }

                    // validater week conter
                    if (weekCounter[visit.week.data] > data.dayFreq) {
                        newVisit.week.error = newVisit.week.error
                            ? newVisit.week.error
                            : { patternError: true };
                    }

                    // initial counter by week number
                    if (weekCounter[visit.week.data]) {
                        weekCounter[visit.week.data] += 1;
                    }

                    return newVisit;
                });

                // validasi order jika visit kurang
                if (data.visits.length < data.numberOfVisits) {
                    var isSomeVisitPatternError =  visitsPattern.some(function(vp){
                        return vp.week.error;
                    });

                    if (isSomeVisitPatternError) {
                        visitsPattern.forEach(function(vp){
                            vp.week.error = vp.week.error ? vp.week.error : { patternError: true };
                        });
                    }
                }
                return visitsPattern;
            } else if (data.dayFreq > 1) {
                return data.visits.map(function (visit, idx) {
                    // Salin objek visit untuk menghindari mutasi
                    var newVisit = {
                        week: {
                            data: visit.week.data,
                            error: visit.week.error || null,
                        },
                        day: {
                            data: visit.day.data,
                            error: visit.day.error || null,
                        },
                        sequence: {
                            data: visit.sequence.data,
                            error: visit.sequence.error || null,
                        },
                    };

                    var dayExist = uniqueDays.find(function (ud) {
                        return ud === visit.day.data;
                    });

                    if (!dayExist && uniqueDays.length <= data.dayFreq) {
                        uniqueDays.push(visit.day.data);
                    }

                    if (uniqueDays.length > data.dayFreq) {
                        newVisit.day.error = newVisit.day.error ? newVisit.day.error : { patternError: true };
                    }

                    // initial counter by week number
                    if (!weekCounter[visit.week.data]) {
                        weekCounter[visit.week.data] = 1;
                    }

                    // validater week conter
                    if (weekCounter[visit.week.data] > data.dayFreq) {
                        newVisit.week.error = newVisit.week.error
                            ? newVisit.week.error
                            : { patternError: true };
                    }

                    // initial counter by week number
                    if (weekCounter[visit.week.data]) {
                        weekCounter[visit.week.data] += 1;
                    }

                    return newVisit;
                });
            }
        }

        function getOrdinalSuffix(n) {
            var j = n % 10;
            var k = n % 100;

            if (j === 1 && k !== 11) {
                return n + 'st';
            }
            if (j === 2 && k !== 12) {
                return n + 'nd';
            }
            if (j === 3 && k !== 13) {
                return n + 'rd';
            }
            return n + 'th';
        }

        function deleteRow(id) {
            vm.tableData = vm.tableData.filter(function (tb) {
                return tb.id !== id;
            });

            // revalidate all stop
            vm.tableData.forEach(function (i) {
                if (i.stop.data && i.stop.util.selected) {
                    var findDuplicate = vm.tableData.filter(function (j) {
                        return j.stop.data == i.stop.data;
                    });

                    i.stop.util.error = findDuplicate.length > 1 ? { duplicate: true } : null;
                }

                i.rowValid = validateRowData(i);
            });

            vm.totalItems = vm.tableData.length;
        }

        function inputFormatter(model) {
            if (!model) {
                return;
            }
            return model.name;
        }

        function onSelectStop(item, id) {
            var idx = vm.tableData.findIndex(function (td) {
                return td.id === id;
            });

            var table = vm.tableData[idx];

            table.stop.util.editMode = false;
            table.stop.data = item.externalId;
            table.stop.util.selected = item;

            table.visitFreq.data = item.visitFreq;
            table.visitFreq.util.error = null;

            table.dayFreq = Number(item.visitFreq.split('')[0]);
            table.weekFreq = Number(item.visitFreq.split('')[2]);

            // revalidate visits
            table.numberOfVisits = getNumberOfVisits(table);
            table.weekOpts = getWeekOptions(table);
            table.dayOpts = getDayOptions(table);

            // resolve visits
            resolveVisit(table);

            // revalidate all stop
            vm.tableData.forEach(function (i) {
                if (i.stop.data && i.stop.util.selected) {
                    var findDuplicate = vm.tableData.filter(function (j) {
                        return j.stop.data == i.stop.data;
                    });

                    i.stop.util.error = findDuplicate.length > 1 ? { duplicate: true } : null;
                }

                i.rowValid = validateRowData(i);
            });
        }

        function onSelectTerritory(item, id) {
            var idx = vm.tableData.findIndex(function (td) {
                return td.id === id;
            });

            vm.tableData[idx].territory.util.editMode = false;
            vm.tableData[idx].territory.data = item.id;
            vm.tableData[idx].territory.util.selected = item;
            vm.tableData[idx].territory.util.error = null;
            vm.tableData[idx].rowValid = validateRowData(vm.tableData[idx]);
        }

        function closeOtherActiveField() {
            vm.tableData.forEach(function (td) {
                var territory = vm.territories.find(function (t) {
                    return t.name == td.territory.data;
                });

                var stop = vm.outlets.find(function (o) {
                    return o.externalId == td.stop.data;
                });

                if (!td.territory.util.selected) {
                    td.territory.util.selected = territory;
                }

                if (!td.stop.util.selected) {
                    td.stop.util.selected = stop;
                }

                td.stop.util.editMode = false;
                td.territory.util.editMode = false;
                td.firstWeek.util.editMode = false;
                td.firstDay.util.editMode = false;
            });
        }

        function newTableData() {
            return vm.tableData.filter(function (td) {
                var week = td.firstWeek.data ? String(td.firstWeek.data).toLowerCase() : '';
                var day = td.firstDay.data ? String(td.firstDay.data).toLowerCase() : '';
                var visitFreq = td.visitFreq.data ? String(td.visitFreq.data).toLowerCase() : '';
                var territory = renderTerritoryData(td).toLowerCase();
                var stop = renderStopData(td).toLowerCase();

                var onlyInvalid = vm.filter.invalid ? !td.rowValid : true;
                var containsWeek = vm.filter.firstWeek
                    ? week.includes(vm.filter.firstWeek.toLowerCase())
                    : true;
                var containsDay = vm.filter.firstDay ? day.includes(vm.filter.firstDay.toLowerCase()) : true;
                var containsVisitFreq = vm.filter.visitFreq
                    ? visitFreq.includes(vm.filter.visitFreq.toLowerCase())
                    : true;
                var containsTerritory = vm.filter.territory
                    ? territory.includes(vm.filter.territory.toLowerCase())
                    : true;
                var containsStop = vm.filter.stop ? stop.includes(vm.filter.stop.toLowerCase()) : true;

                return (
                    onlyInvalid &&
                    containsWeek &&
                    containsDay &&
                    containsVisitFreq &&
                    containsTerritory &&
                    containsStop
                );
            });
        }

        function invalidRow() {
            return vm.tableData.reduce(function (sumInvalid, each) {
                sumInvalid = !each.rowValid ? sumInvalid + 1 : sumInvalid;
                return sumInvalid;
            }, 0);
        }

        function validRow() {
            return vm.tableData.reduce(function (sumValid, each) {
                sumValid = each.rowValid ? sumValid + 1 : sumValid;
                return sumValid;
            }, 0);
        }

        function onFirstWeekUpdate(id) {
            var idx = vm.tableData.findIndex(function (td) {
                return td.id === id;
            });
            var table = vm.tableData[idx];

            table.firstWeek.util.editMode = false;
            table.firstWeek.util.error = null;
            table.visitsError = validateVisitGroup(table);

            if (table.firstWeek.util.error || table.firstDay.util.error) {
                return;
            }

            resolveVisit(table);
        }

        function onFistDayUpdate(id) {
            var idx = vm.tableData.findIndex(function (td) {
                return td.id === id;
            });
            var table = vm.tableData[idx];

            table.firstDay.util.editMode = false;
            table.firstDay.util.error = null;
            table.visitsError = validateVisitGroup(table);

            if (table.firstWeek.util.error || table.firstDay.util.error) {
                return;
            }

            resolveVisit(table);
        }

        function validateVisitGroup(data) {
            var visitFreqError = data.visitFreq.util.error;
            var firstWeekError = data.firstWeek.util.error && data.firstWeek.util.error.required;
            var firstDayError = data.firstDay.util.error && data.firstDay.util.error.required;

            if (visitFreqError) {
                return { stopNotRegistered: true };
            } else if (firstWeekError || firstDayError) {
                return { firstWeekDayInvalid: true };
            } else if (data.visits && data.visits.length) {
                var someExcessive = data.visits.some(function (visit) {
                    return visit.week.error && visit.week.error.excessiveRoute;
                });

                var someWeekOrDayError = data.visits.some(function (visit) {
                    return visit.week.error || visit.day.error;
                });

                var someSeqError = data.visits.some(function (visit) {
                    return visit.sequence.error;
                });

                if (someExcessive) {
                    // hide button resolve
                    return { excessiveRoute: true };
                } else if (someWeekOrDayError) {
                    return { invalidPattern: true };
                } else if (someSeqError) {
                    // hide button resolve
                    return { invalidSequence: true };
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }

        function getNumberOfVisits(data) {
            if (data.visitFreq.util.error) {
                return null;
            }

            var dayFreq = data.dayFreq;
            var weekFreq = data.weekFreq;
            var numberOfVisits = dayFreq && weekFreq ? (vm.maxWeek / weekFreq) * dayFreq : null;

            return numberOfVisits;
        }

        function removeExcessiveVisit(id) {
            var idx = vm.tableData.findIndex(function (td) {
                return td.id === id;
            });
            var table = vm.tableData[idx];

            var numberOfVisits = table.numberOfVisits;
            var currentVisits = table.visits.length;
            var deleteCount = currentVisits - numberOfVisits;

            table.visits.splice(numberOfVisits, deleteCount);

            // proses validasi visits
            table.visits = validateVisitFormat(table);
            table.visits = validateVisitPattern(table);
            table.visitsError = validateVisitGroup(table);

            table.rowValid = validateRowData(table);
        }

        function onSequenceUpdate(id) {
            var idx = vm.tableData.findIndex(function (td) {
                return td.id === id;
            });

            var table = vm.tableData[idx];

            table.visits = validateVisitFormat(table);
            table.visitsError = validateVisitGroup(table);

            table.rowValid = validateRowData(table);
        }

        function closeEsc(e) {
            var keyCode = e.originalEvent.keyCode;
            if (keyCode === 27) {
                closeOtherActiveField();
            }
        }

        function resolveVisit(data, uniqueDays) {
            var visitsHasErrorSequence = data.visitsError && data.visitsError.invalidSequence;

            if (data.visitFreq.util.error || !vm.maxDay || !vm.maxWeek) {
                return;
            }

            // resolve sequence when error sequence
            if (visitsHasErrorSequence) {
                resolveSequence(data);
                data.rowValid = validateRowData(data);
                return;
            }

            var visitFreq = data.visitFreq.data.split('');
            var dayFreq = Number(visitFreq[0]);
            var weekFreq = Number(visitFreq[2]);
            var firstDay = data.firstDay.util.error ? 1 : Number(data.firstDay.data);
            var firstWeek = data.firstWeek.util.error ? 1 : Number(data.firstWeek.data);

            var dayweek = [dayFreq, weekFreq];

            var visitMatrix = RouteDayWeekGen.visitMatrix(
                dayweek,
                firstDay,
                firstWeek,
                vm.maxDay,
                vm.maxWeek,
                uniqueDays
            );

            visitMatrix = visitMatrix.map(function (vm) {
                return { week: vm.w, day: vm.d };
            });

            visitMatrix = sortWeekDay(visitMatrix);

            data.visits = visitMatrix.map(function (vm, idx) {
                var dataVisit = {
                    number: getOrdinalSuffix(idx + 1),
                    week: {
                        data: vm.week,
                        error: null,
                    },
                    day: {
                        data: vm.day,
                        error: null,
                    },
                    sequence: {
                        data: null,
                        error: null,
                    },
                };

                return dataVisit;
            });

            data.visitsError = null;
            data.firstWeek.data = visitMatrix[0].week;
            data.firstWeek.util.error = null;
            data.firstDay.data = visitMatrix[0].day;
            data.firstDay.util.error = null;

            data.rowValid = validateRowData(data);
        }

        function resolveAllVisit() {
            vm.tableData.forEach(function (data) {
                resolveVisit(data);
            });
        }

        function showResolveErrorShedule() {
            if (!vm.tableData.length) {
                return false;
            }

            var show = vm.tableData.some(function (td) {

                if (td.visitsError && td.visitsError.stopNotRegistered) {
                    return false;
                } else if (td.visitsError) {
                    return true;
                } else {
                    return false;
                }
            });

            return show;
        }

        function getWeekOptions(data) {
            var weeks = [];

            // visit freq required
            if (data.visitFreq.util.error) {
                return weeks;
            }

            if (data.weekFreq == 1) {
                // 1w
                weeks.push(1);
            } else if (data.weekFreq > 1 && data.weekFreq < vm.maxWeek) {
                // (n)w
                for (var j = 1; j <= data.weekFreq; j++) {
                    weeks.push(j);
                }
            } else if (data.weekFreq == vm.maxWeek) {
                // (n)w == maxWeek
                for (var i = 1; i <= vm.maxWeek; i++) {
                    weeks.push(i);
                }
            }

            return weeks;
        }

        function getDayOptions(data) {
            var days = [];

            // visit freq required
            if (data.visitFreq.util.error) {
                return days;
            }

            days = DayGap.findDayOpts(vm.maxDay, data.dayFreq);
            return days;
        }

        function errorTooltips(error) {
            if (!error) {
                return '';
            } else if (error.required) {
                return 'required';
            } else if (error.notNumber) {
                return 'number only';
            } else if (error.outOfRange) {
                return 'out of range';
            } else if (error.duplicateRoute) {
                return 'duplicate';
            } else if (error.patternError) {
                return 'wrong pattern';
            }
        }

        function addVisit(data) {
            var uniqueDays = [];

            var visitMoreThanOrEqual = data.visits.length >= data.numberOfVisits;
            var visitFreqError = data.visitFreq.util.error;
            var firstWeekError = data.firstWeek.util.error && data.firstWeek.util.error.required;
            var firstDayError = data.firstDay.util.error && data.firstDay.util.error.required;
            var someWeekOrDayError = data.visits.some(function (visit) {
                return visit.week.error || visit.day.error;
            });

            if (
                visitFreqError ||
                firstWeekError ||
                firstDayError ||
                visitMoreThanOrEqual ||
                someWeekOrDayError
            ) {
                return;
            }

            if (data.dayFreq > 1) {
                data.visits.forEach(function (visit) {
                    var isDayExist = uniqueDays.find(function (ud) {
                        return ud == visit.day.data;
                    });

                    if (!isDayExist) {
                        uniqueDays.push(Number(visit.day.data));
                    }
                });
            }

            if (uniqueDays.length == data.dayFreq) {
                resolveVisit(data, uniqueDays);
            } else {
                resolveVisit(data);
            }
        }

        function resolveSequence(data) {
            data.visits.forEach(function (visit) {
                visit.sequence.data = visit.sequence.error ? '' : visit.sequence.data;
                visit.sequence.error = null;
            });
            data.visitsError = validateVisitGroup(data);
        }
    }
})();
